<template>
  <div class="main-body">
    <a-tabs>
      <a-tab-pane key="1" tab="全部"></a-tab-pane>
      <a-tab-pane key="2" tab="模块"></a-tab-pane>
      <a-tab-pane key="3" tab="插件"></a-tab-pane>
    </a-tabs>
    <a-table :columns="columns" :data-source="data" :pagination="false">
      <template #bodyCell="{ column }">
        <template v-if="column.dataIndex === 'action'">
          <a-button type="primary" size="small">安装</a-button>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { defineComponent } from "vue";

const columns = [
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "介绍",
    dataIndex: "description",
    key: "description",
    ellipsis: true,
  },
  {
    title: "作者",
    dataIndex: "author",
    key: "author",
    width: 160,
  },
  {
    title: "版本",
    dataIndex: "version",
    key: "version",
    width: 100,
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
  },
];
const data = [
  {
    key: "1",
    name: "会员基础模块",
    description: "系统必备的会员基础模块！",
    author: "Crate官方",
    version: "1.0.0",
  },
  {
    key: "2",
    name: "内容管理系统",
    description:
      "简单强大的内容管理系统(文档管理、下载、全文搜索、会员发布、Uniapp移动端版本等)",
    author: "Crate官方",
    version: "1.0.0",
  },
  {
    key: "3",
    name: "B2C电商系统",
    description:
      "小程序商城、uni-app商城、短视频商城、多用户商城系统、新零售系统、商城系统开发，手机商城系统",
    author: "Crate官方",
    version: "1.0.0",
  },
  {
    key: "4",
    name: "API接口文档",
    description: "根据注释自动生成API文档、样式优美",
    author: "Crate官方",
    version: "1.0.0",
  },
];

export default defineComponent({
  setup() {
    return {
      data,
      columns,
    };
  },
});
</script>